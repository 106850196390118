<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="550" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<div v-show="scope.row.sfyjtype!=1"
								style="display: flex;align-items: center;justify-content: center;">
								<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
								<el-button @click="lian(scope.row)" v-show="(scope.row.dfhtype==1||scope.row.dfhtype==2)&&scope.row.state!=2"
									type="primary" size="mini" style="margin-left: 10px;">
									提交答复
								</el-button>

								<el-button @click="zyj(scope.row)" v-show="scope.row.dfhtype==1&&scope.row.state!=2"
									type="primary" size="mini" style="margin-left: 10px;">
									办理承诺
								</el-button>

								<el-button @click="lian(scope.row,2)" v-show="scope.row.dfhtype==3&&scope.row.state!=2"
									type="primary" size="mini" style="margin-left: 10px;">
									查看答复
								</el-button>
								<el-button @click="zyj(scope.row,2)" v-show="scope.row.dfhtype!=1&&scope.row.state!=2&&scope.row.blcl"
									type="primary" size="mini" style="margin-left: 10px;">
									查看承诺
								</el-button>
								<el-button @click="bulian(scope.row)" v-show="scope.row.dfhtype==1&&scope.row.state!=2"
									type="danger" size="mini" style="margin-left: 10px;">
									提交异议
								</el-button>
								<el-button @click="lookyy(scope.row)" v-show="scope.row.cbdw_type==4" type="danger"
									size="mini" style="margin-left: 10px;">
									查看异议
								</el-button>
								<!-- <el-button @click="chaosong(scope.row)"
									v-show="scope.row.dfhtype==3&&scope.row.state!=2&&scope.row.dfhcs==0" type="primary"
									size="mini" style="margin-left: 10px;">
									抄送
								</el-button> -->
								<el-button v-show="scope.row.yxta==1" type="primary" size="mini" @click="setzd(scope.row,1)">设为推荐优秀</el-button>
								<el-button v-show="scope.row.yxta==2" type="warning" size="mini" @click="setzd(scope.row,2)">取消推荐优秀</el-button>
								<el-button v-show="scope.row.sfmy" @click="lookmy(scope.row)" type="success"
									size="mini" style="margin-left: 10px;">
									查看反馈
								</el-button>
								<el-button v-show="scope.row.sfmy==1"  @click="lian(scope.row,3)" type="warning"
									size="mini" style="margin-left: 10px;">
									重新答复
								</el-button>
							</div>
							<div v-show="scope.row.sfyjtype==1"
								style="display: flex;align-items: center;justify-content: center;">
								<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
								<el-button @click="caina(scope.row,1)" v-show="scope.row.sfcn==0" type="primary"
									size="mini" style="margin-left: 10px;">
									采纳
								</el-button>
								<el-button @click="caina(scope.row,2)" v-show="scope.row.sfcn==0" type="warning"
									size="mini" style="margin-left: 10px;">
									不采纳
								</el-button>
							</div>
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">
			<div v-show="rowdata.sfyjtype!=1" slot="otherbtn" style="display: flex;flex-direction: column;">
				<el-button @click="lian(rowdata)" v-show="(rowdata.dfhtype==2||rowdata.dfhtype==1)&&rowdata.state!=2" type="primary"
					size="small" style="margin-bottom:10px;margin-left: 10px;">
					提交答复
				</el-button>
				<el-button @click="lian(rowdata,2)" v-show="rowdata.dfhtype==3&&rowdata.state!=2" type="primary"
					size="small" style="margin-bottom:10px;margin-left: 10px;">
					查看答复
				</el-button>
				<el-button @click="zyj(rowdata,2)" v-show="rowdata.dfhtype!=1&&rowdata.state!=2&&rowdata.blcl" type="primary"
					size="small" style="margin-bottom:10px;margin-left: 10px;">
					查看承诺
				</el-button>
				<el-button @click="bulian(rowdata)" v-show="rowdata.dfhtype==1&&rowdata.state!=2" type="danger"
					size="small" style="margin-bottom:10px;margin-left: 10px;">
					提交异议
				</el-button>
				<el-button @click="zyj(rowdata)" v-show="rowdata.dfhtype==1&&rowdata.state!=2" type="warning"
					size="small" style="margin-bottom:10px;margin-left: 10px;">
					办理承诺
				</el-button>
				<!-- <el-button @click="chaosong(rowdata)" v-show="rowdata.type==3&&rowdata.state!=2&&rowdata.dfhcs==0"
					type="primary" size="mini" style="margin-bottom:10px;margin-left: 10px;">
					抄送
				</el-button> -->
			</div>
			<div v-show="rowdata.sfyjtype==1" slot="otherbtn" style="display: flex;flex-direction: column;">
				<el-button @click="caina(rowdata,1)" v-show="rowdata.sfcn==0" type="primary" size="small"
					style="margin-bottom:10px;margin-left: 10px;">
					采纳
				</el-button>
				<el-button @click="caina(rowdata,2)" v-show="rowdata.sfcn==0" type="warning" size="small"
					style="margin-bottom:10px;margin-left: 10px;">
					不采纳
				</el-button>
			</div>

		</mydetail>

		<!-- 答复函 -->
		<el-dialog custom-class="refuse-dialog" title="承办单位答复函" :visible.sync="lianshow" :before-close="closelian">
			<div style="height:68vh;overflow-x: hidden;overflow-y: auto;">
				<div>
					<div style="border: 1px solid #ccc;width: 100%;">
						<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
							mode="default" />
						<Editor style="height: 500px; overflow-y: hidden;font-size: 16px;" v-model="dafuhan"
							:defaultConfig="editorConfig" mode="default" @onCreated="onCreated" />
					</div>
				</div>
			</div>

			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 10px;">
				<el-button @click="closelian" size="small">取消</el-button>
				<el-button v-show="!islook" type="success" @click="dafu()" size="small">答复</el-button>
			</span>
		</el-dialog>


		<!--办理承诺-->
		<el-dialog title="办理承诺" :visible.sync="yjshow" :before-close="closeyj">
			<div style="height:73vh;overflow-x: hidden;overflow-y: auto;">
				<div>
					<div style="display: flex;align-items: center;margin-bottom: 10px;">
						办理承诺时间：
						<el-date-picker
						      v-model="blcltime"
						      type="datetime"
							  value-format="yyyy-MM-dd hh:mm:ss"
						      placeholder="选择日期">
						    </el-date-picker>
					</div>
					<div style="border: 1px solid #ccc;width: 100%;">
						<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
							mode="default" />
						<Editor style="height: 500px; overflow-y: hidden;font-size: 16px;" v-model="blcn"
							:defaultConfig="editorConfig" mode="default" @onCreated="onCreated" />
					</div>
				</div>
			</div>

			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 10px;">
				<el-button @click="closeyj" size="small">取消</el-button>
				<el-button v-show="!islook" type="success" @click="qryj()" size="small">承诺</el-button>
			</span>
		</el-dialog>

		<!--抄送-->
		<el-dialog title="确认抄送" :visible.sync="showcs" width="40%">
			<div>
				<el-form ref="yjform" label-width="100px">
					<el-form-item label="答复函抄送" required>
						<el-radio-group v-model="chaos_vlaue">
							<el-radio label="1">市委监督室</el-radio>
							<el-radio label="2">市政府信息督查科</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showcs=false">取 消</el-button>
				<el-button type="primary" @click="qrcs">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import mydetail from "@/components/detail_tc.vue";
	export default {
		components: {
			mydetail,
			Editor,
			Toolbar
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: ''
					},
					{
						name: '状态',
						prop: 'cbdw_type',
						width: '120',
						type: 'tags_cbdw'
					},
					{
						name: '是否为建议',
						prop: 'sfyjtype',
						width: '100',
						type: 'tags_isjy'
					},
					{
						name: '是否满意',
						prop: 'sfmy',
						width: '100',
						type: 'tags_sfmy'
					},
				],
				tableData: [],
				rowdata: '',
				lianshow: false,
				czid: '', //当前操作的数据id


				danwei: [],
				taleix: [], //提案类型
				//富文本相关
				editor: null,
				dafuhan: '<p>hello</p>', //答复函
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$URL + '/api/login/upload',
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maximgSize: 10 * 1024 * 1024, // 10M
							// 最多可上传几个文件，默认为 100
							maxNumberOfimgs: 100,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedimgTypes: [],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							meta: {

								// otherKey: 'yyy'
								// img:''
							},
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,

							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},

							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,

							// 超时时间，默认为 10 秒
							timeout: 10 * 1000, //10 秒

							// 上传前
							onBeforeUpload(imgs) {
								Message({
									message: '图片正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();

								if (res.code === 200) {
									Message.success({
										message: "图片上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "图片上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},

							// 单个文件上传成功之后
							onSuccess(img, res) {
								console.log(`${img.name} 上传成功`, res);
							},

							// 单个文件上传失败
							onFailed(img, res) {
								console.log(`${img.name} 上传失败`, res);
							},

							// 上传进度的回调函数
							onProgress(progress) {
								console.log('progress', progress);
								// progress 是 0-100 的数字
							},

							// 上传错误，或者触发 timeout 超时
							onError(img, err, res) {
								console.log(`${img.name} 上传出错`, err, res);
							}
						},

						// 上传视频
						uploadVideo: {
							server: this.$URL + '/api/login/upload',
							fieldName: "file",
							// 单个文件的最大体积限制，默认为 10M
							maxFileSize: 500 * 1024 * 1024, // 500M
							// 最多可上传几个文件，默认为 5
							maxNumberOfFiles: 3,
							// 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ["video/*"],
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 30 秒
							timeout: 30 * 1000, // 15 秒
							onBeforeUpload(imgs) {
								Message({
									message: '正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();

								if (res.code === 200) {
									Message.success({
										message: "视频上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
							// 上传进度的回调函数
							onProgress(progress) {
								console.log("progress", progress);
							},
							// 单个文件上传成功之后
							onSuccess(file, res) {
								console.log(`${file.name} 上传成功`, res);
							},
							// 单个文件上传失败
							onFailed(file, res) {
								console.log(`${file.name} 上传失败`, res);
							},
							// 上传错误，或者触发 timeout 超时
							onError(file, err, res) {
								console.log(`${file.name} 上传出错`, err, res);
							}
						}
					}
				},

				yjshow: false,
				blcn: '',
				selecttype: '', //转意见 交办使用

				showcs: false,
				chaos_vlaue: '1', //抄送值
				islook: false,
				
				login_user_info:'',
				
				blcltime:'',
			}
		},
		mounted() {
			this.gettable()

			//获取承办单位数据
			this.$post({
				url: '/api/organizational/index',
				params: {
					p: 1,
					size: 9999,
					pid: 3
				}
			}).then((res) => {
				this.danwei = res.list
			})
			//获取提案类型
			this.$post({
				url: '/api/lei/index',
				params: {
					p: 1,
					size: 9999
				}
			}).then((res) => {
				this.taleix = res.list
			})
			
			
			this.$post({
				url: '/api/user/dqdetails',
				params: {
					
				}
			}).then((res) => {
				this.login_user_info = res
			})
		},
		methods: {
			setzd(item,i){
				//i--1 设为推荐优秀、2 取消推荐优秀
				this.$post({
					url: '/api/proposa/qtcz',
					params: {
						ids:item.proposa_id,
						yxta:i==1?2:i==2?1:i==3?3:i==4?2:1
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			
			lookmy(item){
				let myd = item.sfmy==1?'不满意':item.sfmy==2?'基本满意':'满意'
				this.$alert('委员满意度：'+myd+'；反馈内容：'+item.qhzxyjb, '委员反馈', {
					confirmButtonText: '确定',
					callback: action => {
						
					}
				})
			},
			lookyy(item) {
				this.$alert('异议：'+item.yyly, '查看异议', {
					confirmButtonText: '确定',
					callback: action => {
						
					}
				})
			},
			//采纳
			caina(item, i) {
				let tips = i == 1 ? '采纳' : '不采纳'
				let msg = '请确认是否 ' + tips + ' 该建议'
				this.$confirm(msg, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/proposa/cnyj',
						params: {
							id: item.id,
							sfcn: i
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {

				})
			},

			//抄送
			qrcs() {
				this.$post({
					url: '/api/proposa/csblcn',
					params: {
						id: this.czid,
						csbm: this.chaos_vlaue
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.showcs = false
					this.gettable()
				})
			},
			chaosong(item) {
				this.czid = item.id
				this.showcs = true
			},



			//===========================办理承诺
			qryj() { //办理承诺
				if(!this.blcltime){
					this.$message.warning('请选择办理承诺时间')
					return
				}
				this.$post({
					url: '/api/proposa/tjblcn',
					params: {
						id: this.czid,
						blcl: this.blcn,
						blcltime:this.blcltime
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.closeyj()
					this.gettable()
				})
			},
			zyj(item, index) {
				// 创建一个 Date 对象
				var currentDate = new Date();
				
				// 获取年、月、日、小时、分钟和秒
				var year = currentDate.getFullYear();
				var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
				var day = ("0" + currentDate.getDate()).slice(-2);
				var hours = ("0" + currentDate.getHours()).slice(-2);
				var minutes = ("0" + currentDate.getMinutes()).slice(-2);
				var seconds = ("0" + currentDate.getSeconds()).slice(-2);
				
				// 格式化时间字符串
				var formattedDateTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
				
				this.czid = item.id
				this.blcltime = ''
				this.blcn =
					'<h2 style="text-align: center;"><span style="color: rgb(68, 68, 68);">关于对'+item.name+'</span></h2><h2 style="text-align: center;"><span style="color: rgb(68, 68, 68);">提案办理承诺书</span></h2><p><br></p><p><span style="color: rgb(68, 68, 68);"><strong>'+item.user_name+'：</strong></span></p><p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp; 您提出的《'+item.name+'》提案收悉，现将办理情况答复如下:</span></p><p><span style="color: rgb(51, 51, 51);"> 老旧小区增设电梯目前我县尚没有先例,结合三位一体管理模式，我们正在与慎城镇和社区探讨可行性操作模式，借鉴外地成功的经验做法，争取早日摸索出适合颍上的操作方案。我们将联合其他部门，做好前期工作。</span></p><p><br></p><p style="text-align: right;"><span style="color: rgb(68, 68, 68);">'+this.login_user_info.name+'  </span></p><p style="text-align: right;"><span style="color: rgb(68, 68, 68);">'+formattedDateTime+'</span></p><p>	</p><p style="text-align: center;"><span style="color: rgb(68, 68, 68);">（联系人：'+this.login_user_info.name+' ；联系电话：'+this.login_user_info.phone+' ）</span></p>'
				if (index == 2 || index == '2') {
					this.islook = true
					this.$post({
						url: '/api/proposa/cbdwdetails',
						params: {
							id: item.id,
						}
					}).then((res) => {
						this.blcn = res.blcl
						this.blcltime = res.blcltime
					})
					this.yjshow = true

				} else {
					this.yjshow = true
				}

			},
			closeyj() {
				this.blcn = ''
				this.yjshow = false
				this.islook = false
			},
			//===========================办理承诺END


			bulian(item) {
				this.$prompt('请输入异议理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /.+/,
					inputErrorMessage: '请输入'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/proposa/tcyy',
						params: {
							id: item.id,
							yyly: value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				})
			},



			//===========================答复
			closelian() {
				this.dafuhan = ''
				this.lianshow = false
				this.islook = false
			},
			dafu() {
				this.$post({
					url: '/api/proposa/tjdfh',
					params: {
						id: this.czid,
						dfh: this.dafuhan
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.closelian()
					this.gettable()
				})
			},
			lian(item, index) { //立案	
				// 创建一个 Date 对象
				var currentDate = new Date();
				
				// 获取年、月、日、小时、分钟和秒
				var year = currentDate.getFullYear();
				var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
				var day = ("0" + currentDate.getDate()).slice(-2);
				var hours = ("0" + currentDate.getHours()).slice(-2);
				var minutes = ("0" + currentDate.getMinutes()).slice(-2);
				var seconds = ("0" + currentDate.getSeconds()).slice(-2);
				
				// 格式化时间字符串
				var formattedDateTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
						
				this.czid = item.id
				this.dafuhan =
					'<p style="text-align: center;"><span style="color: rgb(247, 49, 49); font-size: 29px;"><strong>'+this.login_user_info.name+'</strong></span></p><h5></h5><hr/><p><br></p><h2 style="text-align: center;"><span style="color: rgb(68, 68, 68);">关于对' +
					item.name +
					'</span></h2><h2 style="text-align: center;"><span style="color: rgb(68, 68, 68);">提案办理答复的函</span></h2><p><br></p><p><span style="color: rgb(68, 68, 68);"><strong>'+item.user_name+'：</strong></span></p><p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp; 您提出的《'+item.name+'》提案收悉，现将办理情况答复如下:</span></p><p><span style="color: rgb(51, 51, 51);"> 老旧小区增设电梯目前我县尚没有先例,结合三位一体管理模式，我们正在与慎城镇和社区探讨可行性操作模式，借鉴外地成功的经验做法，争取早日摸索出适合颍上的操作方案。我们将联合其他部门，做好前期工作。</span></p><p><br></p><p><br></p><p style="text-align: right;"><span style="color: rgb(68, 68, 68);">'+this.login_user_info.name+' </span></p><p style="text-align: right;"><span style="color: rgb(68, 68, 68);">'+formattedDateTime+'</span></p><p>	</p><p style="text-align: center;"><span style="color: rgb(68, 68, 68);">（联系人：'+this.login_user_info.name+'；联系电话：'+this.login_user_info.phone+'）</span></p>'
				if (index == 2 || index == '2'||index == 3 || index == '3') {
					this.islook = true
					if(index == 3 || index == '3'){
						this.islook = false
					}
					this.$post({
						url: '/api/proposa/cbdwdetails',
						params: {
							id: item.id,
						}
					}).then((res) => {
						this.dafuhan = res.dfh
					})
					this.lianshow = true

				} else {
					this.lianshow = true
				}
			},
			//===========================答复END






			look(item) {
				this.rowdata = item
				this.detail_id = item.proposa_id
				this.showdetail = true
			},
			goaddnew() {
				this.$router.push('/system/add_zhengji')
			},
			addnew() {
				this.$router.push('/system/swiper_add')
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/cbdwindex',
					params: {
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					res.list.forEach((item, index) => {
						let cbdw_type = 1 //1未答复、2已答复、3已办理、4、有异议--提案 5、采纳--意见，6、不采纳--意见
						if (item.dfhtype == 1 && item.state == 2) {
							cbdw_type = 4
						} else if (item.dfhtype != 1) {
							cbdw_type = item.dfhtype
						} else if (item.dfhtype == 1 && item.sfcn == 0) {
							cbdw_type = 1
						} else if (item.dfhtype == 1 && item.sfcn == 1) {
							cbdw_type = 5
						} else if (item.dfhtype == 1 && item.sfcn == 2) {
							cbdw_type = 6
						}
						item.cbdw_type = cbdw_type
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/admin/banner/delete',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$router.push({
					path: '/system/swiper_edit',
					query: {
						id: row.id
					}
				})
			},
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},

		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>